var {config} = window;
jQuery(document).ready(function($) {
	$('.accordion-heading').click(function() {
		var $this = $(this),
		$container = $this.parents('.accordion'),
		accordion_id = $this.data('accordion'),
		close = false,
		$content = $("#"+accordion_id);

		if (!$content.data('animating')) {
			if ($this.hasClass('active')) {
				close = true;
			}

			$container.find('.accordion-heading, .accordion-content').removeClass('active');
			$container.find('.accordion-content').slideUp({
				duration: 250,
				easing: 'linear'
			});

			if (!close) {
				$this.addClass('active');
				$content.slideToggle({
					duration: 250,
					easing: 'linear',
					start: function() {
						$content.data('animating', true);
						$content.addClass('active');
					},
					done: function() {
						$content.data('animating', false);
					}
				});
			}
		}
	});

	$('.accordion-heading.active').each(function(i, el) {
		var accordion_id = $(el).data('accordion');
		$('#'+accordion_id).slideDown({
			duration: 250,
			done: function() {
				$('#'+accordion_id).clearQueue();
			}
		});
	});
});