jQuery(document).ready(function($) {
	$('.modal-open').click(function() {
		let modal = $(this).data('modal');
		$(modal).css('display', 'flex');
		$('body').css('overflow', 'hidden');
	});

	//Close Modal by clicking Cross
	$('.modal-close').click(function() {
		$('.modal').css('display','none');
		$('body').css('overflow', 'scroll');
		stopModalVideo();
	});

	//Close Modal with Escape Key
	$(document).on('keydown', function(e) {
		if (e.key == 'Escape') {
			$('.modal').css('display','none');
			$('body').css('overflow', 'scroll');
			stopModalVideo();
		}
	});

	//Close Modal by clicking outside the Modal
	$(window).click(function(event) {
		var target = $(event.target);
		if (target.is($('.modal'))) {
			$(target).css('display','none');
			$('body').css('overflow', 'scroll');
			stopModalVideo();
		};
	});
});

// Stop all embedded videos playing after the modal is closed.
function stopModalVideo() {
	$('.modal-content iframe').each(function(){
		var leg = $(this).attr('src');
		$(this).attr('src', leg);
	});
}
